<template>
  <form
    @submit.prevent="submit"
    v-if="authUser"
    :class="isLoading ? 'is-busy' : ''"
    class="card"
  >
    <div class="card-body">
      <h5 class="card-title">Profile</h5>

      <div class="my-3 form-floating">
        <input
          v-model.trim="profile.firstName"
          type="text"
          class="form-control"
          id="firstname"
          placeholder="First name"
        >
        <label for="firstname">First name<sup class="text-danger">*</sup></label>
        <div class="form-text">Minimum 3 characters.</div>
      </div>

      <div class="my-3 form-floating">
        <input
          v-model.trim="profile.middleName"
          type="text"
          class="form-control"
          id="middlename"
          placeholder="Middle name"
        >
        <label for="middlename">Middle name</label>
      </div>

      <div class="my-3 form-floating">
        <input
          v-model.trim="profile.lastName"
          type="text"
          class="form-control"
          id="lastname"
          placeholder="Last name"
        >
        <label for="lastname">Last name<sup class="text-danger">*</sup></label>
        <div class="form-text">Minimum 3 characters.</div>
      </div>

      <div class="my-3">
        <label for="bio" class="form-label">Bio</label>
        <TextEditor v-model.trim="profile.bio"></TextEditor>
      </div>

      <div class="my-3 form-floating">
        <input
          @input="validateDob"
          v-model.trim="profile.dob"
          :class="isDobValid ? '' : 'is-invalid'"
          class="form-control"
          type="text"
          id="dob"
          placeholder="Date of birth"
        >
        <label for="dob">Date of birth</label>
        <div
          :class="isDobValid ? '' : 'invalid-feedback'"
          class="form-text"
        >Format: DD-MM-YYYY.</div>
      </div>

      <div class="my-3 form-floating">
        <select v-model.trim="profile.gender" class="form-select" id="gender">
          <option value="" disabled selected>Please select one</option>
          <option
            v-for="(gender, genderIndex) in genders"
            :key="`key-${genderIndex}`"
            :value="gender.value"
          >{{ gender.label }}</option>
        </select>
        <label for="gender">Gender</label>
      </div>

      <div class="my-3 form-floating">
        <input
          v-model.trim="profile.location"
          type="text"
          class="form-control"
          id="location"
          placeholder="Location"
        >
        <label for="location">Location</label>
      </div>

      <div class="my-3 form-floating">
        <input
          v-model.trim="profile.height"
          type="text"
          class="form-control"
          id="height"
          placeholder="Height"
        >
        <label for="height">Height</label>
      </div>

      <!-- <div class="my-3 form-floating">
        <select v-model.trim="profile.unit" class="form-select" id="unit">
          <option value="" disabled selected>Please select one</option>
          <option
            v-for="(unit, unitIndex) in units"
            :key="`key-${unitIndex}`"
            :value="unit.value"
          >{{ unit.label }}</option>
        </select>
        <label for="unit">Unit preference</label>
      </div> -->

      <ErrorMessage v-if="error" :error="error"></ErrorMessage>

      <div v-if="isSuccess" class="alert alert-success">Profile has been updated!</div>

      <SubmitButton
        :text="'Update profile'"
        :textBusy="'Please wait...'"
        :isLoading="isLoading"
        :disabled="isLoading || isSubmitDisabled"
        class="btn btn-primary"
      ></SubmitButton>
    </div>
  </form>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { mapState } from 'vuex';
import { deepCopy } from '@/core/util';

dayjs.extend(customParseFormat);

export default {
  name: 'UserProfileForm',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    TextEditor: () => import('@/components/TextEditor'),
  },
  watch: {
    authUser() {
      this.syncProfile();
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    isSubmitDisabled() {
      const { profile, profileBase } = this;
      if (JSON.stringify(profile) === JSON.stringify(profileBase)) return true;
      const { firstName, lastName } = this.profile;
      return firstName.length < 3 || lastName.length < 3;
    },
  },
  methods: {
    syncProfile() {
      const {
        firstName,
        middleName,
        lastName,
        bio,
        dob,
        gender,
        location,
        height,
        unit,
      } = this.authUser;
      const p = {
        firstName,
        middleName,
        lastName,
        bio,
        dob,
        gender: gender || '',
        location,
        height,
        unit,
      };
      this.profileBase = deepCopy(p);
      this.profile = deepCopy(p);
    },
    validateDob(e) {
      const { value } = e.currentTarget;

      if (value.trim() === '') {
        this.isDobValid = true;
        return;
      }

      const regEx = /(\d{2})-(\d{2})-(\d{4})/;
      if (!value.match(regEx)) {
        this.isDobValid = false;
        return;
      }

      const dt = dayjs(value, 'DD-MM-YYYY', true);
      if (!dt.isValid()) {
        this.isDobValid = false;
        return;
      }

      this.isDobValid = true;
    },
    async submit() {
      this.isLoading = true;
      this.isSuccess = false;
      this.error = null;
      try {
        const { gender } = this.profile;
        if (gender === '') {
          this.profile.gender = null;
        }
        await this.$store.dispatch('auth/updateProfile', this.profile);
        this.isSuccess = true;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      isDobValid: true,
      error: null,
      profileBase: {},
      profile: {},
      units: [
        {
          label: 'Metric system',
          value: 'metric',
        },
        {
          label: 'Imperial (US)',
          value: 'imperial',
        },
      ],
      genders: [
        {
          label: 'Male',
          value: 'm',
        },
        {
          label: 'Female',
          value: 'f',
        },
        {
          label: 'Rather not say',
          value: 'x',
        },
      ],
    };
  },
  created() {
    this.syncProfile();
  },
};
</script>
